<template>
	<examSingleText v-if="item.modelType == 'single_text'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<examMultiRowText v-else-if="item.modelType == 'multiRow_text'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<examRadio v-else-if="item.modelType == 'radio'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<examChecked v-else-if="item.modelType == 'checked'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<examSelect v-else-if="item.modelType == 'select'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<examImagesRadio v-else-if="item.modelType == 'imagesRadio'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<examImagesChecked v-else-if="item.modelType == 'imagesChecked'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<examLcBlanks v-else-if="item.modelType == 'lc-blanks'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<examLcHblanks v-else-if="item.modelType == 'lc-hblanks'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
</template>
<script>
import examChecked from "@/components/examWidget/examChecked";
import examRadio from "@/components/examWidget/examRadio";
import examImagesChecked from "@/components/examWidget/examImagesChecked";
import examImagesRadio from "@/components/examWidget/examImagesRadio";
import examLcBlanks from "@/components/examWidget/examLcBlanks";
import examLcHblanks from "@/components/examWidget/examLcHblanks";
import examMultiRowText from "@/components/examWidget/examMultiRowText";
import examSelect from "@/components/examWidget/examSelect";
import examSingleText from "@/components/examWidget/examSingleText";
export default {
	components: {
		examChecked,
		examRadio,
		examImagesChecked,
		examImagesRadio,
		examLcBlanks,
		examLcHblanks,
		examSelect,
		examSingleText,
		examMultiRowText
	},
	props: {
		item: {
			type: Object,
		},
		globalItemStyle: {
			type: Object,
		},
		logicFlag:{
			type:Boolean
		},
		list:{
			type:Array
		}
	},
	methods:{
		
	}
};
</script>
<style lang="less">

</style>