<template>
    
	<div class="field-mitem multiRow_text" type="multiRow_text" :id="detial.uuid" v-if="!logicFlag || (logicFlag && detial.jumpShow && detial.logicShow)">
        <!-- 单选题 -->
        <div class="field_warp">
            <h5 class="field_title" :class="{gross_input:(detial.dataId)}">
                <i v-if="detial.topic_required" class="required">*</i>
                <span class="txt" :style="{fontSize: globalItemStyle.mLabelStyle.fontSize + 'px',color: globalItemStyle.mLabelStyle.color,fontWeight: globalItemStyle.mLabelStyle.fontWeight,fontStyle: globalItemStyle.mLabelStyle.fontStyle,textDecoration: globalItemStyle.mLabelStyle.textDecoration}">{{index+1}}.{{detial.topicName}}</span>
                <div class="set_gross" v-if="detial.dataId">得分<el-input size="small" type="text" readonly="readonly" v-model="detial.topicScore"></el-input></div>
            </h5>
            <p class="field_remark" v-if="detial.remarks">{{detial.remarks}}</p>
            <div class="item_contianer">
                <el-input class="ques_input" type="textarea" :rows="3" :placeholder="detial.placeholder" v-model="detial.value"></el-input>
            </div>
            <div class="answer-result" v-if="detial.dataId">
                <span class="font-green" v-if="detial.topicScore>0">回答正确</span>
                <span class="font-red" v-else>回答错误</span>
            </div>
        </div>
	</div>
</template>
<script>
//import $ from "jquery";
export default {
	props: {
		detial: {
			type: Object,
		},
        index: {
            type:Number
        },
        globalItemStyle: {
			type: Object,
		},
		logicFlag:{
			type:Boolean
		},
		list:{
			type:Array
		}
	},
	computed: {
	},
	watch: {
        list:{
            handler(){
                this.$nextTick(()=>{
                    let show = 0;
                    if(this.detial.showLogic && this.detial.showLogic.length > 0){
                        if(this.detial.showLogicCondition == 1){
                            this.detial.showLogic.forEach(item => {
                                this.list.forEach(o=>{
                                    if(item.logicTopic == o.uuid){
                                        if(item.logicCondition == 1){
                                            if(item.quesItem.modelType == 'scale'){
                                                (item.logicStart <= o.value && item.logicEnd >= o.value)&&show++;
                                            }else{
                                                if(o.value){
                                                    o.value.includes(item.logicOption)&&show++;
                                                }
                                                
                                            }
                                            
                                        }else if(item.logicCondition == 2){
                                            if(item.quesItem.modelType == 'scale'){
                                                (item.logicStart > o.value && item.logicEnd < o.value)&&show++;
                                            }else{
                                                if(o.value){
                                                    !(o.value.includes(item.logicOption))&&show++;
                                                }
                                            }
                                        }else if(item.logicCondition == 3){
                                            if(o.logicShow && o.jumpShow){
                                                show++;
                                            }
                                             
                                        }else if(item.logicCondition == 4){
                                            if(!o.logicShow || !o.jumpShow){
                                                show++;
                                            }
                                        }
                                    }
                                })
                            });
                            if(show == this.detial.showLogic.length){
                                this.detial.logicShow = true;
                            }else{
                                this.detial.logicShow = false;
                            }
                        }else{
                            this.detial.showLogic.forEach(item => {
                                this.list.forEach(o=>{
                                    if(item.logicTopic == o.uuid){
                                        if(item.logicCondition == 1){
                                            if(item.quesItem.modelType == 'scale'){
                                                (item.logicStart <= o.value && item.logicEnd >= o.value)&&show++;
                                            }else{
                                                o.value.includes(item.logicOption)&&show++;
                                            }
                                            
                                        }else if(item.logicCondition == 2){
                                            if(item.quesItem.modelType == 'scale'){
                                                (item.logicStart > o.value && item.logicEnd < o.value)&&show++;
                                            }else{
                                                o.value.includes(item.logicOption)&&show++;
                                            }
                                        }else if(item.logicCondition == 3){
                                            if(o.logicShow && o.jumpShow){
                                                show++;
                                            }
                                             
                                        }else if(item.logicCondition == 4){
                                            if(!o.logicShow || !o.jumpShow){
                                                show++;
                                            }
                                        }
                                    }
                                })
                            });
                            if(show > 0){
                                this.detial.logicShow = true;
                            }else{
                                this.detial.logicShow = false;
                            }
                        }
                        
                    }
                })
            },
			deep: true,
			immediate: true,
        },
        detial:{
            handler(){
                this.$nextTick(()=>{
                    if(this.detial.logicJumps && this.detial.logicJumps.length > 0){
                        let sort = 0;
                        this.detial.logicJumps.forEach(item => {
                            this.list.forEach(o=>{
                                if(this.detial.uuid == o.uuid && o.logicShow && o.jumpShow){
                                    let newItem = this.list.filter(c => c.uuid == item.logicTopic)
                                    sort = newItem[0].sort;
                                }
                            })
                        });
                        this.list.forEach(o=>{
                            if(sort){
                                if(o.sort < sort && o.sort > this.detial.sort){
                                    o.jumpShow = false;
                                }else if(o.sort >= sort){
                                    o.jumpShow = true;
                                }
                            }
                        })
                    }
                    if(this.detial.value || this.detial.value != ''){
                        this.detial.topicScore = 0;
                        if(this.detial.modelType == 'checked'||this.detial.modelType == 'imagesChecked'){
                            if(this.detial.scoreFormula == 0){
                                if(this.detial.value.length === this.detial.answer.length && this.detial.value.every((ele) => this.detial.answer.includes(ele))){
                                    this.detial.topicScore = Number(this.detial.scoreGross);
                                }
                            }else if(this.detial.scoreFormula == 1){
                                let score = 0;
                                let err = 0;
                                this.detial.value.forEach(item => {
                                    this.detial.topicOption.forEach(option => {
                                        if(option.textKey == item){
                                            if(option.isAnswer == 1){
                                                score += Number(option.optionScore);
                                            }else{
                                                err++
                                            }
                                        }
                                    })
                                })
                                if(err>0){
                                    this.detial.topicScore = 0
                                }else{
                                    this.detial.topicScore = score
                                }
                            }else if(this.detial.scoreFormula == 2){
                                let score = 0;
                                this.detial.value.forEach(item => {
                                    this.detial.topicOption.forEach(option => {
                                        if(option.textKey == item){
                                            score += Number(option.optionScore);
                                        }
                                    })
                                })
                                this.detial.scoreGross = score
                            }
                            
                        }else if(this.detial.modelType == 'select'||this.detial.modelType == 'radio'||this.detial.modelType == 'imagesRadio'){
                            if(this.detial.scoreFormula == 1){
                                this.detial.topicOption.forEach(option => {
                                    if(option.textKey == this.detial.value){
                                        this.detial.topicScore = Number(option.optionScore);
                                    }
                                })
                            }else if(this.detial.scoreFormula == 0){
                                if(this.detial.value == this.detial.answer){
                                    this.detial.topicScore = Number(this.detial.scoreGross);
                                }
                            }
                        }else if(this.detial.modelType == 'single_text'||this.detial.modelType == 'multiRow_text'){
                            if(this.detial.scoreFormula == 1){
                                if( this.detial.answer.includes(this.detial.value)){
                                    this.detial.topicScore = Number(this.detial.scoreGross);
                                }
                            }else if(this.detial.scoreFormula == 0){
                                if(this.detial.value == this.detial.answer){
                                    this.detial.topicScore = Number(this.detial.scoreGross);
                                }
                            }
                        }else if(this.detial.modelType == 'lc-blanks'){
                            if(this.detial.scoreFormula == 1){
                                if(this.detial.scoreAnswer == 0){
                                    let score = 0;
                                    this.detial.topicOption.forEach((option,index) => {
                                        if(option.answerItemVal == this.detial.value[index]){
                                            score += Number(option.optionScore);
                                        }
                                    })
                                    this.detial.topicScore = score
                                }else{
                                    let score = 0;
                                    this.detial.topicOption.forEach((option,index) => {
                                        if(option.answerItemVal.includes(this.detial.value[index])){
                                            score += Number(option.optionScore);
                                        }
                                    })
                                    this.detial.topicScore = score
                                }
                            }else if(this.detial.scoreFormula == 0){
                                if(this.detial.scoreAnswer == 0){ 
                                    let err = 0;
                                    this.detial.topicOption.forEach((option,index) => {
                                        if(option.answerItemVal != this.detial.value[index]){
                                            err++;
                                        }
                                    })
                                    if(err>0){
                                        this.detial.topicScore = 0
                                    }else{
                                        this.detial.topicScore = this.detial.scoreGross
                                    }
                                }else{
                                    let err = 0;
                                    this.detial.topicOption.forEach((option,index) => {
                                        if(!option.answerItemVal.includes(this.detial.value[index])){
                                            err++;
                                        }
                                    })
                                    if(err>0){
                                        this.detial.topicScore = 0
                                    }else{
                                        this.detial.topicScore = this.detial.scoreGross
                                    }
                                }
                                
                            }
                        }else if(this.detial.modelType == 'lc-hblanks'){
                            let answerItemVal = this.detial.topicOption[0].answerItemVal;
                            let optionScore = this.detial.topicOption[0].optionScore;
                            if(this.detial.scoreFormula == 1){
                                if(this.detial.scoreAnswer == 0){
                                    let score = 0;
                                    answerItemVal.forEach((option,index) => {
                                        if(option == this.detial.value[index]){
                                            score += Number(optionScore[index]);
                                        }
                                    })
                                    this.detial.topicScore = score
                                }else{
                                    let score = 0;
                                    answerItemVal.forEach((option,index) => {
                                        if(option.includes(this.detial.value[index])){
                                            score += Number(optionScore[index]);
                                        }
                                    })
                                    this.detial.topicScore = score
                                }
                            }else if(this.detial.scoreFormula == 0){
                                if(this.detial.scoreAnswer == 0){ 
                                    let err = 0;
                                    answerItemVal.forEach((option,index) => {
                                        if(option != this.detial.value[index]){
                                            err++;
                                        }
                                    })
                                    if(err>0){
                                        this.detial.topicScore = 0
                                    }else{
                                        this.detial.topicScore = this.detial.scoreGross
                                    }
                                }else{
                                    let err = 0;
                                    answerItemVal.forEach((option,index) => {
                                        if(!option.includes(this.detial.value[index])){
                                            err++;
                                        }
                                    })
                                    if(err>0){
                                        this.detial.topicScore = 0
                                    }else{
                                        this.detial.topicScore = this.detial.scoreGross
                                    }
                                }
                                
                            }
                        }
                    }
                })
            },
			deep: true,
			immediate: true,
        }
	},
    methods:{
    }
};
</script>
<style lang="less" scoped>
.field-mitem{
    padding: 0 10px 10px;
    cursor: move;
    position: relative;
    overflow: hidden;
    .field_title{
        font-size: 14px;
        line-height: 1.8;
        padding: 10px 0 5px;
        position: relative;
        .required{
            font-weight: 700;
            color: #f00;
            position: absolute;
            top: 18px;
            left: -10px;
            line-height: 1;
        }
    }
    .field_title.gross_input{
        padding-right:100px;
        .set_gross{
            position:absolute;
            right:0;
            top:5px;
            .el-input{
                width:60px;
                margin-left:5px;
            }
        }
    }
    .answer-result{
        padding:5px 10px;
        background:#f5f5f5;
        margin-top:5px;
        font-size:15px;
        font-weight:bold;
    }
    .field_remark{
        padding: 0 0 0 1em;
        line-height: 1.8;
        color: #999;
    }
    .item_contianer{
        
        textarea.form-control{
            height: 130px;
        }
        
    }
}

</style>