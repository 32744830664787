<template>
	<mExamSingleText v-if="item.modelType == 'single_text'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<mExamMultiRowText v-else-if="item.modelType == 'multiRow_text'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<mExamRadio v-else-if="item.modelType == 'radio'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<mExamChecked v-else-if="item.modelType == 'checked'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<mExamSelect v-else-if="item.modelType == 'select'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<mExamImagesRadio v-else-if="item.modelType == 'imagesRadio'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<mExamImagesChecked v-else-if="item.modelType == 'imagesChecked'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<mExamLcBlanks v-else-if="item.modelType == 'lc-blanks'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<mExamLcHblanks v-else-if="item.modelType == 'lc-hblanks'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
</template>
<script>
import mExamChecked from "@/components/examWidgetM/mExamChecked";
import mExamRadio from "@/components/examWidgetM/mExamRadio";
import mExamImagesChecked from "@/components/examWidgetM/mExamImagesChecked";
import mExamImagesRadio from "@/components/examWidgetM/mExamImagesRadio";
import mExamLcBlanks from "@/components/examWidgetM/mExamLcBlanks";
import mExamLcHblanks from "@/components/examWidgetM/mExamLcHblanks";
import mExamMultiRowText from "@/components/examWidgetM/mExamMultiRowText";
import mExamSelect from "@/components/examWidgetM/mExamSelect";
import mExamSingleText from "@/components/examWidgetM/mExamSingleText";
export default {
	components: {
		mExamChecked,
		mExamRadio,
		mExamImagesChecked,
		mExamImagesRadio,
		mExamLcBlanks,
		mExamLcHblanks,
		mExamMultiRowText,
		mExamSelect,
		mExamSingleText,
	},
	props: {
		item: {
			type: Object,
		},
		globalItemStyle: {
			type: Object,
		},
		logicFlag:{
			type:Boolean
		},
		list:{
			type:Array
		}
	},
};
</script>
<style lang="less">

</style>